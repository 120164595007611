<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row class="d-inline-block text-center">
          <v-col>
            <v-img class="rotate" src="~@/assets/images/logo.svg" height="100px" width="100px" contain></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoadingSyncioLogo"
}
</script>

<style scoped>
.rotate {
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>